import BreadCrumb from 'Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { getServices } from 'helpers/get';
import { Tabledata } from './DataTables/Tabledata';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { deleteServices } from 'helpers/delete';
import Swal from 'sweetalert2';

import { useAuth } from 'context/AuthContext';
import { User } from 'interface/user';
// Import Components


const Users = () => {
    document.title = "Admin | Azerbaijan Tours";
const [articles, setArticles] = useState<any>([]);
const { t } = useTranslation();
const {user} = useAuth();
const navigate = useNavigate();

const [isDeleite, setIsDeleite] = useState<boolean>(false);
const Deleite = async (userId: string)  => {
  const result = await Swal.fire({
    title: 'Əminsiniz',
    text: "Bunu geri qaytara bilməyəcəksiniz!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Bəli, silin!',
    cancelButtonText: 'Bağla'
  });

  
  if (result.isConfirmed) {
  const response = await deleteServices(`/Auth/RemoveUser/${userId}`,user,navigate)
 
  if (response.statusCode===200) {
    IsDeleite();
    Swal.fire({
      title: 'Silindi!',
      text:`İstifadəçi uğurla silindi`,
      icon: 'success'
    });
  }
  }}
  const IsDeleite =  () => {
    setIsDeleite(!isDeleite)
  }


    useEffect(() => {
        const fetchData = async () => {
          const data = await getServices<User[]>("/Auth/GetAllUser",user);
   
         
          setArticles(data);
        };
    
        fetchData();
      }, [isDeleite]);
       
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="İstifadəçi" pageTitle="Admin" />
                    <Col lg={12}>
          <Card>
         
      
            <Tabledata Deleite={Deleite} data={articles} />
    
          </Card>
        </Col>
                    
                   
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Users;
