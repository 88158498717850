import React from 'react';

//import Scss
import "assets/scss/themes.scss";

//imoprt Route
import Route from 'Routes/Index';


import { AuthProvider } from './context/AuthContext';


const App = () => {

  return (
    <AuthProvider>
      <Route />
    </AuthProvider>
  );
}

export default App;