import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import multipluplouds from "helpers/multipluplouds";
import { Erorr } from "../../../../types/auth";
import { useAuth } from "context/AuthContext";
import { postServices } from "helpers/post";
import { Includes } from "interface/include";
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";

export interface FileWithPreview {
    file: File;
    preview: string;
}

export const TourCreate = () => {
    const { user } = useAuth();
    const [loader, setLoader] = useState<boolean>(false);
    const navigate = useNavigate();
    const [error, setError] = useState<Erorr>();
    const [incldata, setIncldata] = useState<any>([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getServices<Includes[]>("/Include/getallbylang", user, 'en-US');
                console.log("includes", data);
                setIncldata(data);
            } catch (error) {
                console.error("Error fetching include data:", error);
            }
        };
        fetchData();
    }, [user]);

    const formik = useFormik({
        initialValues: {
            includeIds: [] as string[],
            image: [],
            map: "",
            price: 0,
            isFeatured: false,
            en: {
                name: "",
                subName: "",
                metaKey: "",
                metaDescription: "",
                description: "",
                destination: "",
            },
            ru: {
                name: "",
                subName: "",
                metaKey: "",
                metaDescription: "",
                description: "",
                destination: "",
            },
           
        },
        validationSchema: Yup.object({

            includeIds: Yup.array().min(1, 'minimum 1 seçim ').required("Zəhmət olmasa tura daxil edilənləri yazın"),
            map: Yup.string().required("Zəhmət olmasa xəritənin linkini yazın"),
            price: Yup.number()
                .min(1, 'Qiymət 0-dan böyük olmalıdır')
                .required('Qiymət vacibdir'),
            image: Yup.array().min(2, 'minimum 2 şəkil ').required('Şəkil vacibdir'),
          
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa turun adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa turun təsvirini daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa turun metaKey daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa turun metaDescription daxil edin"),
                subName: Yup.string().required("Zəhmət olmasa turun alt başlığını daxil edin"),
                destination: Yup.string().required("Zəhmət olmasa turun başlanğıc nöqtəsini daxil edin")
            }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa turun adını daxil edin"),
                description: Yup.string().required("Zəhmət olmasa turun təsvirini daxil edin"),
                metaKey: Yup.string().required("Zəhmət olmasa turun metaKey daxil edin"),
                metaDescription: Yup.string().required("Zəhmət olmasa turun metaDescription daxil edin"),
                subName: Yup.string().required("Zəhmət olmasa turun alt başlığını daxil edin"),
                destination: Yup.string().required("Zəhmət olmasa turun başlanğıc nöqtəsini daxil edin")
            })
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoader(true);
            try {
                const imageUrl = await multipluplouds(values.image, user, navigate);
                setError(imageUrl);

                if (imageUrl.statusCode === 200) {
                    const requestBody = {
                        isFeatured: values.isFeatured,
                        includeIds: values.includeIds,
                        map: values.map,
                        price: values.price,
                        photoPath: imageUrl.message[0].path || "",
                        addTourImagesDTOs: imageUrl.message.map((msg: any) => ({
                            fileName: msg.path,
                            path: msg.path
                        })),
                        addTourLanguageDTOs: [
                           
                            {
                                name: values.ru.name,
                                subName: values.ru.subName,
                                description: values.ru.description,
                                langCode: "ru-RU",
                                metaKey: values.ru.metaKey,
                                metaDescription: values.ru.metaDescription,
                                destination: values.ru.destination,
                            },
                            {
                                name: values.en.name,
                                subName: values.en.subName,
                                description: values.en.description,
                                langCode: "en-US",
                                metaKey: values.en.metaKey,
                                metaDescription: values.en.metaDescription,
                                destination: values.en.destination,
                            }
                        ],
                    };

                    const response = await postServices("/Tour", requestBody, user, navigate);
                    console.log(response);
                    
                    if (response.statusCode === 200) {
                        navigate("/tour");
                        resetForm();
                        setLoader(false);
                        setError({ statusCode: 0, message: '' });
                    }
                }
            } catch (error) {
                console.error("Error during form submission:", error);
            }
            setLoader(false);
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yarat" pageTitle="Tur" />
                    {error?.statusCode === 400 && <Alert variant="danger">{error.message}</Alert>}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col xxl={4}>
                                            <div className="mb-3">
                                                <label htmlFor="image" className="form-label">Tur şəkli</label>
                                                <input
                                                    id="image"
                                                    type="file"
                                                    multiple
                                                    accept="image/png,image/jpeg"
                                                    className={`form-control ${formik.touched.image && formik.errors.image ? 'border border-danger' : ''}`}
                                                    onChange={(event) => {
                                                        const files = event.currentTarget.files;
                                                        if (files) {
                                                            const filesArray: FileWithPreview[] = Array.from(files).map((file) => ({
                                                                file,
                                                                preview: URL.createObjectURL(file),
                                                            }));
                                                            formik.setFieldValue('image', filesArray);
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.image && formik.errors.image ? (
                                                    <div className="text-danger">{formik.errors.image}</div>
                                                ) : null}
                                                <ul className="list-unstyled mb-0" id="dropzone-preview">
                                                    {formik.values.image.length > 0 &&
                                                        formik.values.image.map((photo: any, index) => (
                                                            <li key={index} className="mt-2 dz-processing dz-image-preview dz-success dz-complete">
                                                                <div className="border rounded">
                                                                    <div className="d-flex p-2">
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div className="avatar-sm bg-light rounded">
                                                                                <img className="img-fluid rounded d-block" src={photo.preview} alt={photo.file.name} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col xxl={8}>
                                            <Form action="#" onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(); return false; }}>
                                                <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                                    <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                    <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.en !== undefined && "text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.ru !== undefined && "text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
                                                        </Nav.Item>
                                                      
                                                    </Nav>

                                                    <Tab.Content className="tab-content" id="nav-tabContent">
                                                        

                                                        <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.name"
                                                                    name="ru.name"
                                                                    placeholder="Turun adını daxil edin"
                                                                    value={formik.values.ru.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.name} />
                                                                {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                           
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="ru.subName"
                                                                            name="ru.subName"
                                                                            placeholder="Turun alt başlığını daxil edin"
                                                                            value={formik.values.ru.subName}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.ru?.subName} />
                                                                        {formik.errors.ru?.subName && formik.touched.ru?.subName ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.ru.subName}</Form.Control.Feedback>
                                                                        ) : null}
                                                                    </div>
                                                                
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.description"
                                                                    name="ru.description"
                                                                    placeholder="Turun təsvirini daxil edin"
                                                                    value={formik.values.ru.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.description} />
                                                                {formik.errors.ru?.description && formik.touched.ru?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="ru.metaDescription"
                                                                    name="ru.metaDescription"
                                                                    placeholder="Turun meta təsvirini daxil edin"
                                                                    value={formik.values.ru.metaDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.metaDescription} />
                                                                {formik.errors.ru?.metaDescription && formik.touched.ru?.metaDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.ru.metaDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.metaKey"
                                                                    name="ru.metaKey"
                                                                    placeholder="Turun metaKeyini daxil edin"
                                                                    value={formik.values.ru.metaKey}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.metaKey} />
                                                                {formik.errors.ru?.metaKey && formik.touched.ru?.metaKey ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.metaKey}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="ru.destination"
                                                                            name="ru.destination"
                                                                            placeholder="Turun başlanğıc nöqtəsini daxil edin"
                                                                            value={formik.values.ru.destination}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.ru?.destination} />
                                                                        {formik.errors.ru?.destination && formik.touched.ru?.destination ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.ru.destination}</Form.Control.Feedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                
                                                            </Row>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.name"
                                                                    name="en.name"
                                                                    placeholder="Turun adını daxil edin"
                                                                    value={formik.values.en.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.name} />
                                                                {formik.errors.en?.name && formik.touched.en?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                          
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="en.subName"
                                                                            name="en.subName"
                                                                            placeholder="Turun alt başlığını daxil edin"
                                                                            value={formik.values.en.subName}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.en?.subName} />
                                                                        {formik.errors.en?.subName && formik.touched.en?.subName ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.en.subName}</Form.Control.Feedback>
                                                                        ) : null}
                                                                    </div>
                                                              
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.description"
                                                                    name="en.description"
                                                                    placeholder="Turun təsvirini daxil edin"
                                                                    value={formik.values.en.description}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.description} />
                                                                {formik.errors.en?.description && formik.touched.en?.description ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.description} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3">
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    id="en.metaDescription"
                                                                    name="en.metaDescription"
                                                                    placeholder="Turun meta təsvirini daxil edin"
                                                                    value={formik.values.en.metaDescription}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.metaDescription} />
                                                                {formik.errors.en?.metaDescription && formik.touched.en?.metaDescription ? (
                                                                    <Form.Control.Feedback type="invalid"> {formik.errors.en.metaDescription} </Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.metaKey"
                                                                    name="en.metaKey"
                                                                    placeholder="Turun metaKeyini daxil edin"
                                                                    value={formik.values.en.metaKey}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.metaKey} />
                                                                {formik.errors.en?.metaKey && formik.touched.en?.metaKey ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.metaKey}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="my-3">
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="en.destination"
                                                                            name="en.destination"
                                                                            placeholder="Turun başlanğıc nöqtəsini daxil edin"
                                                                            value={formik.values.en.destination}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            isInvalid={!!formik.errors.en?.destination} />
                                                                        {formik.errors.en?.destination && formik.touched.en?.destination ? (
                                                                            <Form.Control.Feedback type="invalid">{formik.errors.en.destination}</Form.Control.Feedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                               
                                                            </Row>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                                <div className="my-3">
                                                    <label htmlFor="price" className="form-label">Qiymət</label>
                                                    <input
                                                        type="number"
                                                        id="price"
                                                        name="price"
                                                        placeholder="Qiymət daxil edin"
                                                        className={`form-control ${formik.touched.price && formik.errors.price ? 'is-invalid' : ''}`}
                                                        value={formik.values.price}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.price && formik.errors.price ? (
                                                        <div className="invalid-feedback">{formik.errors.price}</div>
                                                    ) : null}
                                                </div>
                                                <div className="my-3">
                                                    <label htmlFor="map" className="form-label">Xəritə</label>
                                                    <input
                                                        type="text"
                                                        id="map"
                                                        name="map"
                                                        placeholder="Xəritənin linkini daxil edin"
                                                        className={`form-control ${formik.touched.map && formik.errors.map ? 'is-invalid' : ''}`}
                                                        value={formik.values.map}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.map && formik.errors.map ? (
                                                        <div className="invalid-feedback">{formik.errors.map}</div>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="">Qiymətə daxildir</label>
                                                    <Form.Control
                                                        as="select"
                                                        name="includeIds"
                                                        multiple
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.includeIds}
                                                        isInvalid={!!formik.errors.includeIds}
                                                    >

                                                        {incldata && incldata.map((x: Includes, index: number) => (
                                                            <option key={index} value={x.id}>
                                                                {x.name}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                    {formik.errors.includeIds && formik.touched.includeIds ? (
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.includeIds}
                                                        </Form.Control.Feedback>
                                                    ) : null}
                                                </div>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="isFeatured"
                                                        label="Top Destination"
                                                        name="isFeatured"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        checked={formik.values.isFeatured}
                                                        isInvalid={!!formik.errors.isFeatured}
                                                    />
                                                    {formik.errors.isFeatured && formik.touched.isFeatured ? (
                                                        <Form.Control.Feedback type="invalid">
                                                            {formik.errors.isFeatured}
                                                        </Form.Control.Feedback>
                                                    ) : null}
                                                </div>




                                                <Button type="submit" className="btn btn-primary w-100" disabled={loader}>
                                                    {loader && <Spinner size="sm" animation="border" />} {"  "}
                                                    <span className="btn-text">Yadda saxla</span>
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
