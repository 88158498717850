import { Route, useLocation, useNavigate } from "react-router-dom";

import { useEffect } from "react";
import { useAuth } from "context/AuthContext";


const AuthProtected = (props: any) => {


  const {loading} = useAuth();
  const navigate=useNavigate()
  const location = useLocation();
  const token = localStorage.getItem('refres');

  useEffect(() => {
    
    if (loading && !token) {
      if (
        location.pathname !== '/auth-404' &&
        location.pathname !== '/auth-500' &&
        location.pathname !== '/auth-503' 
      ) {
        navigate("/login");
      }
      }
  }, [token, loading,navigate,location.pathname]);

  return <>{props.children}</>;
};


const AccessRoute = ({ component: Component, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };


