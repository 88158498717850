import React from "react";

const Navdata = () => {
 

    const menuItems: any = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            id: "tour",
            label: "Tours",
            icon: "ph-article",
            link: "/tour", 
        },
     
        {
            id: "banner",
            label: "Slider",
            icon: "ph-article",
            link: "/banner", 
        },
       
        {
            id: "Includes",
            label: "Includes",
            icon: "ph-article",
            link: "/includes", 
        },
        {
            id: "register",
            label: "Register",
            icon: "ph-article",
            link: "/register", 
        }  , {
            id: "user",
            label: "User",
            icon: "ph-article",
            link: "/user", 
        },
        
        

    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;