import React, { useState } from 'react';
import { Form, Button, InputGroup, Alert, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { postServices } from 'helpers/post';
import { Erorr } from '../../../types/auth';
import { useAuth } from 'context/AuthContext';

const Register =  () => {
const navigate = useNavigate();
const {user} = useAuth();
    
    document.title = " Register | Azerbaijan Tours";

    const [passwordShow, setPasswordShow] = useState<any>(false);
    const [confirmpasswordShow, setconfirmPasswordShow] = useState<any>(false);

    const [loader, setLoader] = useState<boolean>(false);
    const Erorr = {
        statusCode: 0,
        message: '',
      };
    const [error, setError] = useState<Erorr>(Erorr);


    const validation: any =  useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstname: '',
            lastname: '',
            email: '',
            username: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Zəhmət olmasa adınızı daxil edin,8 simvoldan çox olmalıdır"),
            lastname: Yup.string().required("Zəhmət olmasa soyadınızı daxil edin"),
            email: Yup.string().required("Zəhmət olmasa E-poçt ünvanınızı daxil edin"),
            username: Yup.string().required("İstifadəçi adınızı daxil edin"),
            password: Yup.string().required("Zəhmət olmasa şifrənizi daxil edin").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "8 simvol, bir böyük hərf, bir kiçik hərf, bir rəqəm və bir xüsusi simvoldan ibarət olmalıdır"),
            confirmPassword: Yup.string().required("Zəhmət olmasa şifrənizi yeniden daxil edin").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "8 simvol, bir böyük hərf, bir kiçik hərf, bir rəqəm və bir xüsusi simvoldan ibarət olmalıdır"),
        
        }),
        onSubmit: async (values,{resetForm})  => {
            setLoader(true);
            const requestBody = {
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                username: values.username,
                password: values.password,
                confirmPassword: values.confirmPassword
            }
          
           const response= await postServices("/Auth/Register",requestBody,user,navigate)
           setLoader(false);
     
           const Erorr = {
            statusCode: response.statusCode,
            message: response.message || '',
          };
          if (response.statusCode===200) {
            resetForm()
            setError(Erorr)
            navigate("/")
          }
           setError(Erorr)
        
        }
    });


    return (
        <ParticlesAuth>
            <React.Fragment>

                <div className="col-xxl-6 mx-auto">
                    <div className="card mb-0 border-0 shadow-none mb-0">
                        <div className="card-body p-sm-5 m-lg-4">
                            <div className="text-center mt-2">
                                <h5 className="fs-3xl">Hesabınızı yaradın</h5>
                                <p className="text-muted">Azerbaijan Tour hesabınızı indi yaradın</p>
                            </div>
                            <div className="p-2 mt-5">
                                {(error?.statusCode < 400 && 0<error?.statusCode) &&  <Alert variant="success">{error.message}</Alert>}
                                {(error?.statusCode >= 400 && 0<error?.statusCode) && <Alert variant="danger">{error.message}</Alert>}
                             
                                
                               <Form className="needs-validation" action="#" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>
<div className=" d-flex gap-2">
                                <Form.Group className="mb-3 w-100 ">
                                        <Form.Label htmlFor="firstname">Adınız<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" id="firstname" placeholder="Adınızı daxil edin"
                                            name="firstname"
                                            className="form-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.firstname || ""}
                                            isInvalid={
                                                validation.touched.firstname && validation.errors.firstname ? true : false
                                            }
                                        />
                                        {validation.touched.firstname && validation.errors.firstname ? (
                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.firstname}</div></Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                    <Form.Group className="mb-3 w-100 ">
                                        <Form.Label htmlFor="lastname">Soyadınız <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" id="lastname" placeholder="Soyadınızı daxil edin"
                                            name="lastname"
                                            className="form-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.lastname || ""}
                                            isInvalid={
                                                validation.touched.lastname && validation.errors.lastname ? true : false
                                            }
                                        />
                                        {validation.touched.lastname && validation.errors.lastname ? (
                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.lastname}</div></Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                    </div>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="useremail">E-poçt <span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="email" id="useremail" placeholder="E-poçt ünvanınızı daxil edin"
                                            name="email"
                                            className="form-control"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.email || ""}
                                            isInvalid={
                                                validation.touched.email && validation.errors.email ? true : false
                                            }
                                        />
                                        {validation.touched.email && validation.errors.email ? (
                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.email}</div></Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="username">İstifadəçi adınız<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" id="username" placeholder="İstifadəçi adınızı daxil edin"
                                            name="username"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.username || ""}
                                            isInvalid={
                                                validation.touched.username && validation.errors.username ? true : false
                                            }
                                        />
                                        {validation.touched.username && validation.errors.username ? (
                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.username}</div></Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="password-input">Şifrəniz<span className="text-danger">*</span></Form.Label>
                                        <InputGroup className="position-relative auth-pass-inputgroup">
                                            <Form.Control onPaste={(e) => e.preventDefault()} placeholder="Şifrənizi daxil edin" id="password-input" type={!passwordShow ? "password" : "text"}
                                                name="password"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.password || ""}
                                                isInvalid={
                                                    validation.touched.password && validation.errors.password ? true : false
                                                }
                                            />
                                            <Button variant="link" className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                            {validation.touched.password && validation.errors.password ? (
                                                <Form.Control.Feedback type="invalid"><div>{validation.errors.password}</div></Form.Control.Feedback>
                                            ) : null}
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="password-input"> Təkrar şifrə<span className="text-danger">*</span></Form.Label>
                                        <InputGroup className="position-relative auth-pass-inputgroup">
                                            <Form.Control onPaste={(e) => e.preventDefault()} placeholder="Şifrənizi təkrar daxil edin" id="confirmpassword-input" type={!confirmpasswordShow ? "password" : "text"}
                                                name="confirmPassword"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.confirmPassword || ""}
                                                isInvalid={
                                                    validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                                                }
                                            />
                                            <Button variant="link" className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addn" onClick={() => setconfirmPasswordShow(!confirmpasswordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                            {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                                <Form.Control.Feedback type="invalid"><div>{validation.errors.confirmPassword}</div></Form.Control.Feedback>
                                            ) : null}
                                        </InputGroup>
                                    </Form.Group>

                                 
                                    <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                        <h5 className="fs-sm">Şifrədə olmalıdır:</h5>
                                        <p id="pass-length" className="invalid fs-xs mb-2">Minimum <b>8 simvol</b></p>
                                        <p id="pass-lower" className="invalid fs-xs mb-2">Ən azı<b>1 kiçik</b> hərf (a-z)</p>
                                        <p id="pass-upper" className="invalid fs-xs mb-2">Ən azı <b>1 böyük</b> hərf (A-Z)</p>
                                        <p id="pass-number" className="invalid fs-xs mb-0">Ən azı <b>1 rəqəm</b> (0-9)</p>
                                    </div>

                                    <div className="mt-4">
                                        <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                            {loader && <Spinner size="sm" animation="border" />} {"  "}
                                            Qeydiyyat ol
                                        </Button>
                                    </div>

          
                                </Form>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                       Artıq hesabınız var ?{' '}
                                        <Link to={process.env.PUBLIC_URL + "/login"} className="fw-semibold text-primary text-decoration-underline">
                                            Daxil ol
                                        </Link>{' '}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </ParticlesAuth>
    )
}
export default Register;