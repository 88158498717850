import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "Common/BreadCrumb";
import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from "helpers/get";
import { useTranslation } from "react-i18next";
import { putServices } from "helpers/put";
import { useAuth } from "context/AuthContext";
import { Includes, IncludesUpdate } from "interface/include";

export const IncludeUpdate = () => {
    const { id } = useParams<{ id: string }>();
    const [loader, setLoader] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAuth();

    const formik = useFormik({
        initialValues: {
         
            ru: { name: "" },
            en: { name: "" }
        },
        validationSchema: Yup.object({
          
            ru: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            }),
            en: Yup.object().shape({
                name: Yup.string().required("Zəhmət olmasa daxilləri boş buraxmayın"),
            })
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoader(true);
            const requestBody = [
        
                { name: values.ru.name, langCode: "ru-RU" },
                { name: values.en.name, langCode: "en-US" }
            ];
            const response = await putServices(`/Include/${id}`, requestBody, user, navigate);
            setLoader(false);
            if (response.statusCode === 200) {
                navigate("/includes");
                resetForm();
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const data = await getServices<IncludesUpdate[]>(`/Include/${id}`, user);
          
            if (data) {
                formik.setValues({
                   
                    ru: { name: data.find(x => x.langCode === "ru-RU")?.name || "" },
                    en: { name: data.find(x => x.langCode === "en-US")?.name || "" }
                });
            }
        };

        fetchData();
    }, [id, user]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Yeniləyin" pageTitle="Qiymətə daxildir" />
                    <Row>
                        <Col lg={12}>
                            <Card >
                                <Card.Body>
                                    <Row>
                                        <Col xxl={12}>
                                            <Form action="#" onSubmit={(e) => {
                                                e.preventDefault();
                                                formik.handleSubmit();
                                                return false;
                                            }}>
                                                <Tab.Container id="nav-tab" defaultActiveKey="#nav-contact">
                                                    <Nav variant="tabs" className="nav nav-tabs" role="tablist">
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.en !== undefined && "text-danger"}`} eventKey="#nav-contact">English</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className={`${formik.errors.ru !== undefined && "text-danger"}`} eventKey="#nav-profile">Pусский</Nav.Link>
                                                        </Nav.Item>
                                                    
                                                    </Nav>

                                                    <Tab.Content className="tab-content" id="nav-tabContent">
                                                        
                                                        <Tab.Pane eventKey="#nav-profile" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="ru.name"
                                                                    name="ru.name"
                                                                    placeholder="Adını daxil edin"
                                                                    value={formik.values.ru.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.ru?.name} />
                                                                {formik.errors.ru?.name && formik.touched.ru?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.ru.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="#nav-contact" className="tab-pane fade">
                                                            <div className="my-3">
                                                                <Form.Control
                                                                    type="text"
                                                                    id="en.name"
                                                                    name="en.name"
                                                                    placeholder="Adını daxil edin"
                                                                    value={formik.values.en.name}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    isInvalid={!!formik.errors.en?.name} />
                                                                {formik.errors.en?.name && formik.touched.en?.name ? (
                                                                    <Form.Control.Feedback type="invalid">{formik.errors.en.name}</Form.Control.Feedback>
                                                                ) : null}
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                                <Button className="btn btn-primary w-100" type="submit" disabled={loader}>
                                                    {loader && <Spinner size="sm" animation="border" />} {"  "}
                                                    <span className="btn-text">
                                                        Yadda saxla
                                                    </span>
                                                </Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
