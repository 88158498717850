import BreadCrumb from 'Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { getServices } from 'helpers/get';

import { Tabledata } from './DataTables/Tabledata';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from 'context/AuthContext';
import { deleteServices } from 'helpers/delete';
import { TourGet } from 'interface/tourget';
import { patchServices } from 'helpers/patch';

export interface Tour {
  photoPath: string;
  map: string;
  price:  number;
  includeIds: [
  {
    name: string,
    langCode: string
  }
],
  addTourLanguageDTOs: addTourLanguageDTOs[];
  addTourImagesDTOs: addTourImagesDTOs[];
}

export interface addTourLanguageDTOs {
  name: string;
  subName: string;
  description: string;
  metaDescription: string;
  metaKey: string;
  destination: string;
  langCode: string;
}

export interface addTourImagesDTOs {
  fileName: string;
  path: string;
}

export interface Data {
  fileName: string,
  pathOrContainer: string
 
  
};



const Tour = () => {
    document.title = "Admin | Azerbaijan Tours ";
const [articles, setArticles] = useState<any>([]);
const { t } = useTranslation();
const {user} = useAuth();
const navigate = useNavigate();
const [isDeleite, setIsDeleite] = useState<boolean>(false);
const Deleite = async (id: string)  => {
  const result = await Swal.fire({
    title: 'Əminsiniz?',
    text: "Bunu geri qaytara bilməyəcəksiniz!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Bəli, silin!',
    cancelButtonText: 'Bağla'
  });

  if (result.isConfirmed) {
  const response = await patchServices(`/Tour/soft/${id}`,user,navigate)
 
  if (response.statusCode===200) {
    IsDeleite();
    Swal.fire({
      title: 'Silindi!',
      text:`Uğurla silindi`,
      icon: 'success'
    });
  }
  }}
  const IsDeleite =  () => {
    setIsDeleite(!isDeleite)
  }

  useEffect(() => {
    const fetchData = async () => {
      
            const data = await getServices<TourGet[]>("/Tour/getallbylang", user, 'en-US');
            
            setArticles(data); 
      
    };

    fetchData();
}, [ isDeleite]);

       
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Tour" pageTitle="Admin" />
                    <Col lg={12}>
          <Card>
            <Card.Header>
            <Link to={"/tour/create"}> <Button className="btn-primary   bg-gradient">Yarat</Button></Link>
            </Card.Header>
            <Card.Body>
            <Tabledata Deleite={Deleite} data={articles} />
            </Card.Body>
          </Card>
        </Col>
                    
                   
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Tour;
