import React, { useState } from 'react';
import { Card, Col, Button, Form, Alert, Spinner } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import withRouter from 'Common/withRouter';
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from 'context/AuthContext';

const Signin = (props: any) => {
  const { login, error, loading } = useAuth();
  
  
  
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailOrUsername: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailOrUsername: Yup.string().required("Zəhmət olmasa E-poçt və ya istifadəçi adınızı daxil edin"),
      password: Yup.string().required("Zəhmət olmasa şifrənizi daxil edin"),
    }),
    onSubmit: (values) => {
      login(values, props.router.navigate);
    }
  });

  document.title = "Login | Azerbaijan Tours";

  return (
    <ParticlesAuth>
      <React.Fragment>
        <Col xxl="6" className="mx-auto">
          <Card className="mb-0 border-0 shadow-none mb-0">
            <Card.Body className="p-sm-5 m-lg-4">
              <div className="text-center mt-5">
                <h5 className="fs-3xl">Xoş gəlmisiniz</h5>
                <p className="text-muted">Azerbaijan Tours ilə davam etmək üçün daxil olun.</p>
              </div>
              <div className="p-2 mt-5">
              {error && error.message ? (
                                    <Alert variant="danger">
                                        {error.message === "User not found!" ? "İstifadəçi tapılmadı" : error.message}
                                    </Alert>
                                ) : null}
                <Form action="#" onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                  <Form.Group className="mb-3" controlId="formUsername">
                    <Form.Label>E-poçt və ya istifadəçi adı <span className="text-danger">*</span></Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type="text"
                        name="emailOrUsername"
                        className="form-control password-input"
                        placeholder="E-poçt və ya istifadəçi adınızı daxil edin"
                        required
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.emailOrUsername || ""}
                        isInvalid={validation.touched.emailOrUsername && validation.errors.emailOrUsername ? true : false}
                      />
                    </div>
                    {validation.touched.emailOrUsername && validation.errors.emailOrUsername ? (
                      <Form.Control.Feedback type="invalid">{validation.errors.emailOrUsername}</Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Şifrə<span className="text-danger">*</span></Form.Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Form.Control
                        type={passwordShow ? "text" : "password"}
                        className="form-control pe-5 password-input"
                        placeholder="Şifrənizi daxil edin"
                        required
                        name="password"
                        value={validation.values.password || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isInvalid={validation.touched.password && validation.errors.password ? true : false}
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <Form.Control.Feedback type="invalid">{validation.errors.password}</Form.Control.Feedback>
                      ) : null}
                      <Button
                        variant="link"
                        className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                    </div>
                  </Form.Group>
                  <div className="mt-4">
                    <Button className="btn btn-primary w-100" type="submit" disabled={loading}>
                      {loading && <Spinner size='sm' />} {" "}Daxil ol
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default withRouter(Signin);
